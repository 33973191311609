import { useNavigate } from "react-router-dom";
import { Button, Flex, Icon, useDisclosure, useToast } from "@chakra-ui/react";
import { NewSupervisorModal } from "../../../components/Modals/NewSupervisorModal";
import { useClient } from "../../../../../shared/context/client.context";
import { useData } from "../../../../../shared/hooks/useData";
import { EndpointTypes } from "../../../../../shared/utils/Types/EndpointTypes";
import { useRef, useState } from "react";
import { ModalDelete } from "../../../../../shared/components/Modals/ModalDelete";
import { toastNotify } from "../../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../../shared/utils/Types/StatusEnumTypes";
import { handleErrors } from "../../../../../shared/utils/functions/handleErrors";
import { deleteUser } from "../../../../../shared/middlewares/users.middleware";
import { EmptyMessageType } from "../../../../../shared/utils/Types/EmptyMessageType";
import { Table } from "../../../../../shared/components/Table/Table";
import { SupervisoresColumns } from "./Columns/SupervisoresColumns";
import { EntityTypes, useQuerySession } from "../../../../../shared/hooks/useQuerySession";
import { Filters } from "../../../../../shared/components/Filters/Filters";
import { FiltrosNames, FiltrosTypes } from "../../../../../shared/utils/Types/FiltrosEnum";
import { BiPlusCircle } from "react-icons/bi";

export const SupervisoresTable = () => {
    const client = useClient();
    const toast = useToast()
    const { query, setQuery } = useQuerySession({ entity: EntityTypes.SUPERVISORES });
    const { data: supervisores, loading, Refresh } = useData({
        client: client,
        endpoint: EndpointTypes.SUPERVISORES,
        query: query
    });
    const [hasFilter, setHasFilters] = useState<boolean>();

    const { isOpen, onOpen, onClose } = useDisclosure();
    const { isOpen: isOpenDelete, onOpen: onOpenDelete, onClose: onCloseDelete } = useDisclosure();

    const supervisorRef = useRef<any>(null);

    const onEventRemove = (event: any) => {
        supervisorRef.current = event;

        if (isOpenDelete) onCloseDelete();
        onOpenDelete();
    };

    const openAlert = (supervisor: any): boolean => {
        if (supervisor?.activo && supervisor?.meta?.grupos_count !== "0") return true;

        return false;
    }

    const onDelete = () => {
        deleteUser({ userId: supervisorRef.current?.id, client: client })
            .then(() => {
                Refresh()
                toastNotify(toast, StatusEnumTypes.SUCCESS, "Supervisor eliminado correctamente")
            })
            .catch((error: any) => {
                const errors = handleErrors(
                    error?.response?.data?.errors,
                    error?.response?.status
                )

                errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
            })
            .finally(() => onCloseDelete())
    }

    const getKeyWord = (defaultWord: string): string => {
        if (!supervisorRef.current?.nombre || !supervisorRef.current?.apellidos) return defaultWord

        const keyword = supervisorRef.current?.nombre + " " + supervisorRef.current?.apellidos;

        return keyword
    }

    return (
        <Flex direction="column" gap="20px">
            <Filters
                setHasFilters={setHasFilters}
                query={query}
                setQuery={setQuery}
                allowToggle={true}
                filterHeaders={[
                    {
                        name: FiltrosNames.NOMBRE_EMAIL,
                        type: FiltrosTypes.INPUT,
                    },
                ]}
                filterElements={[
                    {
                        name: FiltrosNames.EMPRESA,
                        type: FiltrosTypes.ASYNC_SELECT,
                    },
                    {
                        name: FiltrosNames.ESTADO_USUARIO,
                        type: FiltrosTypes.SELECT,
                    },
                    {
                        name: FiltrosNames.CORREOS_ACTIVOS,
                        type: FiltrosTypes.SELECT,
                    }
                ]}
                button={
                    <Button
                        onClick={onOpen}
                        bg="secondary"
                        color="purewhite"
                        p="8px 15px"
                        borderRadius="5px"
                        fontSize="14px"
                        fontWeight="500"
                        leftIcon={<Icon as={BiPlusCircle} boxSize="20px" />}
                        _hover={{ bg: "variant" }}
                    >
                        Nuevo supervisor
                    </Button>
                }
            />

            <Table
                query={query}
                setQuery={setQuery}
                data={supervisores?.data || []}
                columns={SupervisoresColumns({ onEventRemove })}
                isLoading={loading}
                total={supervisores?.meta?.total || 0}
                emptyMessage={hasFilter ? EmptyMessageType.usuariosFiltros : EmptyMessageType.usuarios}
            />

            <NewSupervisorModal
                isOpen={isOpen}
                onClose={onClose}
                setRefreshTable={Refresh}
            />

            <ModalDelete
                isOpen={isOpenDelete}
                onClose={onCloseDelete}
                onDelete={onDelete}
                label={getKeyWord("supervisor")}
            />
        </Flex>
    )
};