import { Box, Button, Flex, Icon, Image, Text, useDisclosure, useToast } from "@chakra-ui/react"
import { InformationBox } from "../../../../../shared/components/CustomElements/InformationBox/InformationBox"
import { InformationInput } from "@imaginagroup/bit-components.ui.elements.information-input"
import { CursosInt } from "../../../../../interfaces/CursosInt";
import { useEffect, useState } from "react";
import { BiPencil, BiPlusCircle, BiTrashAlt } from "react-icons/bi";
import { InformationFilepond } from "../../../../../shared/components/Information/InformationFilepond";
import { CustomSwitch } from "../../../../../shared/components/CustomElements/CustomSwitch";
import { UserInt } from "../../../../../interfaces/UserInt";
import { InformationTextEditor } from "@imaginagroup/bit-components.ui.elements.information-text-editor"
import { InformationAsyncCard } from "../../../../../shared/components/Information/InformationAsyncCard";
import { UserRolEnum } from "../../../../../shared/utils/Types/UserRolEnum";
import { isRoleAllowed } from "../../../../../shared/utils/functions/validateRol";
import { useAuthContex } from "../../../../../shared/context/auth.context";
import { useClient } from "../../../../../shared/context/client.context";
import CursosPerfilSkeleton from "../../../components/Skeletons/CursosPerfilSkeleton";
import { CustomAvatar } from "../../../../../shared/components/Avatar/CustomAvatar";
import { EndpointTypes } from "../../../../../shared/utils/Types/EndpointTypes";
import { loadData } from "../../../../../shared/utils/functions/loadData";
import { QueryTypes } from "../../../../../shared/utils/Types/QueryTypes";
import { EmpresaInspectorModal } from "../../../components/modals/EmpresaInspector";
import { CopyTooltip } from "../../../../../shared/components/CustomElements/CopyTooltip";
import { useUserPolicy } from "../../../../../shared/hooks/PermissionPolicy";
import { useModulos } from "../../../../../shared/hooks/ModuloFundae";
import { toastNotify } from "../../../../../shared/utils/functions/toastNotify";
import { StatusEnumTypes } from "../../../../../shared/utils/Types/StatusEnumTypes";
import { OnboardingStep } from "../../../../../shared/components/Cards/OnboardingStep";
import { InformationAsyncSelect } from "@imaginagroup/bit-components.ui.elements.information-async-select";
import { informationSelectStyles } from "../../../../../ui/informationSelectStyles";
import { NewTutorModal } from "../../../../Usuarios/components/Modals/NewTutorModal";
import { NewExamenModal } from "../../../../Examenes/components/Modals/NewExamenModal";
import { handleErrors } from "../../../../../shared/utils/functions/handleErrors";
import { addInspectorCurso } from "../../../../../shared/middlewares/inspectores.middleware";
import { useData } from "../../../../../shared/hooks/useData";
import { ExamenInt } from "../../../../../interfaces/ExamenInt";

interface Props {
    curso: CursosInt | undefined;
    updateValue: (value: Record<string, string | string[] | boolean | null>) => Promise<void>;
    setRefresh: () => void;
    loading: boolean;
}

export const TabPerfilCurso = ({ curso, updateValue, setRefresh, loading }: Props) => {
    const toast = useToast();
    const { moduloFUNDAE } = useModulos();
    const { user } = useAuthContex();
    const { gestion, total, contenido } = useUserPolicy();
    const client = useClient();
    const { isOpen: isOpenInspector, onOpen: onOpenInspector, onClose: onCloseInspector } = useDisclosure();
    const { isOpen: isOpenTutor, onOpen: onOpenTutor, onClose: onCloseTutor } = useDisclosure();
    const { isOpen: isOpenExamen, onOpen: onOpenExamen, onClose: onCloseExamen } = useDisclosure();
    const [editNombre, setEditNombre] = useState<boolean>(false);
    const [editDuracion, setEditDuracion] = useState<boolean>(false);
    const [editAccion, setEditAccion] = useState<boolean>(false);
    const [editPlantilla, setEditPlantilla] = useState<boolean>(false);
    const [editExamen, setEditExamen] = useState<boolean>(false);
    const [estado, setEstado] = useState<boolean>()
    const [activo, setActivo] = useState<boolean>()
    const [tutorizado, setTutorizado] = useState<boolean>()
    const [stepCursoActivo, setStepCursoActivo] = useState<boolean>(true);
    const [hasExamenes, setHasExamenes] = useState<boolean>(true);
    const [hasDiplomaPlantillas, setHasDiplomaPlantillas] = useState<boolean>(true);
    const [loadingInspector, setLoadingInspector] = useState<boolean>(false);

    const { data: empresas } = useData({
        client: client,
        endpoint: EndpointTypes.EMPRESA,
        query: {
            interna: "true",
        },
        ignoreRequest: (user?.role?.nombre !== UserRolEnum.ADMIN || gestion || contenido),
    })

    useEffect(() => {
        curso?.publicado === null || curso?.publicado === undefined
            ? setEstado(false)
            : setEstado(curso?.publicado)

        curso?.activo === null || curso?.activo === undefined
            ? setActivo(false)
            : setActivo(curso?.activo)

        curso?.tutorizado === null || curso?.tutorizado === undefined
            ? setTutorizado(false)
            : setTutorizado(curso?.tutorizado)
    }, [curso])

    const loadTutores = async (value: string) => await loadData({
        value: value,
        client: client,
        endpoint: EndpointTypes.USUARIOS,
        query: QueryTypes.NOMBRE_EMAIL,
        specificQuery: QueryTypes.ROL_TUTOR,
        nombreCompleto: true
    })

    const removeTutor = (tutorId: string | undefined | null) => {
        if (!tutorId) return
        const values: any[] = []

        curso?.tutores?.filter(
            (tutor: UserInt) => tutor?.id !== tutorId)
            .map((tutor: UserInt) => values.push(tutor?.id))

        updateValue({ tutores: values })
    }


    const loadPlantillas = async (value: string) => {
        const plantillas = await loadData({
            value: value,
            client: client,
            endpoint: EndpointTypes.PLANTILLAS,
            query: QueryTypes.NOMBRE,
            specificQuery: (QueryTypes.TIPO_PLANTILLA + "diploma") as QueryTypes,
            limit: 100
        });

        setHasDiplomaPlantillas(plantillas && plantillas.length > 0);
        return plantillas;
    }

    const loadExamenes = async (value: string) => {
        const examenes = await loadData({
            value: value,
            client: client,
            endpoint: EndpointTypes.EXAMENES,
            specificQuery: "?libre=true" as QueryTypes,
            query: QueryTypes.NOMBRE,
        });

        setHasExamenes(examenes && examenes.length > 0);
        return examenes;
    }

    const removeExamen = (examenId: string | undefined | null) => {
        if (!examenId) return
        updateValue({ examenId: null })
    }

    const handleTutorClick = () => {
        if (curso?.tutores?.length === 0) {
            onOpenTutor();
        }
    };

    const handleExamenModalClose = () => {
        onCloseExamen();
        setRefresh();
        loadExamenes("");
    };

    const handleNewInspector = () => {
        if (empresas?.data && empresas?.data?.length === 1) {
            if (curso?.inspectores?.find((inspector: any) => inspector?.meta?.pivot_empresa_id === empresas?.data[0]?.id)) {
                toastNotify(toast, StatusEnumTypes.INFO, "La empresa ya tiene un inspector asignado")
                return
            }

            setLoadingInspector(true)

            addInspectorCurso({
                client: client,
                data: {
                    cursoId: curso?.id,
                    empresaId: empresas?.data[0]?.id
                }
            })
                .then(() => setRefresh())
                .catch((error: any) => {
                    const errors = handleErrors(
                        error?.response?.data?.errors,
                        error?.response?.status
                    )

                    errors?.map((error: any) => toastNotify(toast, StatusEnumTypes.ERROR, error?.message))
                })
                .finally(() => setLoadingInspector(false))
        } else {
            onOpenInspector()
        }
    }

    return (
        loading ?
            <CursosPerfilSkeleton moduloFundae={moduloFUNDAE} />
            :

            <Flex gap="20px" h="calc(100vh - 200px)">
                <Flex direction="column" flex="1">
                    <InformationBox
                        title="Datos de la empresa"
                        component={
                            (stepCursoActivo && (!user?.config?.grupos && total)) ?
                                <Flex
                                    position="relative"
                                >
                                    <OnboardingStep
                                        position={{
                                            top: "205px",
                                            left: "-44px"
                                        }}
                                        placement="top/left"
                                        title="Activa tu curso"
                                        description="Para poder asociar el curso y gestionar tus grupos y matriculas, primero debes de activar tu curso."
                                        onClick={() => {
                                            setStepCursoActivo(false)
                                        }}
                                    />
                                </Flex>
                                : undefined
                        }
                    >
                        <Box>
                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                onMouseEnter={() => setEditNombre(true)}
                                onMouseLeave={() => setEditNombre(false)}
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="nombre"
                                    label="Nombre"
                                    defaultValue={curso?.nombre || ""}
                                    updateValue={updateValue}
                                    isRequired={true}
                                    isDisabled={gestion}
                                />

                                <Flex boxSize="20px">
                                    {editNombre && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>

                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                alignItems="center"
                                justifyContent="space-between"
                                pr="35px"
                            >
                                <CustomSwitch
                                    label="Estado"
                                    optionLeft="Publicado"
                                    optionRight="Oculto"
                                    colorSwitch="light_grey"
                                    value={estado}
                                    setValue={setEstado}
                                    onClick={(e) => {
                                        const oldValue = curso?.publicado

                                        updateValue({ publicado: e! }).catch(() => setEstado(oldValue))
                                    }}
                                    isDisabled={gestion}
                                    isLoading={estado !== curso?.publicado}
                                />
                            </Flex>

                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                alignItems="center"
                                justifyContent="space-between"
                                pr="35px"
                            >
                                <CustomSwitch
                                    label="Activo"
                                    optionLeft="Si"
                                    optionRight="No"
                                    colorSwitch="light_grey"
                                    value={activo}
                                    setValue={setActivo}
                                    onClick={(e) => {
                                        const oldValue = curso?.activo

                                        updateValue({ activo: e! }).catch(() => setActivo(oldValue))
                                    }}
                                    isDisabled={gestion}
                                    isLoading={activo !== curso?.activo}
                                />
                            </Flex>

                            <Flex
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                alignItems="center"
                                justifyContent="space-between"
                                pr="35px"
                            >
                                <CustomSwitch
                                    label="Tutorizado"
                                    optionLeft="Si"
                                    optionRight="No"
                                    colorSwitch="light_grey"
                                    value={tutorizado}
                                    setValue={setTutorizado}
                                    onClick={(e) => {
                                        const oldValue = curso?.tutorizado

                                        updateValue({ tutorizado: e! }).catch(() => setTutorizado(oldValue))
                                    }}
                                    isDisabled={gestion}
                                />
                            </Flex>

                            <Flex
                                p="10px 20px"
                                borderBottom="1px solid"
                                borderColor="light_grey"
                                alignItems="center"
                                justifyContent="space-between"
                                onMouseEnter={() => setEditDuracion(true)}
                                onMouseLeave={() => setEditDuracion(false)}
                                gap="10px"
                            >
                                <InformationInput
                                    onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                    name="duracion"
                                    label="horas"
                                    defaultValue={curso?.duracion || ""}
                                    updateValue={updateValue}
                                    isDisabled={gestion}
                                />

                                <Flex boxSize="20px">
                                    {editDuracion && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>

                            <Flex
                                p="10px 20px"
                                alignItems="center"
                                borderBottom={curso?.tutorizado ? "1px solid" : "none"}
                                borderColor="light_grey"
                            >
                                <Text
                                    color="font"
                                    fontSize="12px"
                                    fontWeight="700"
                                    letterSpacing="0.24px"
                                    textTransform="uppercase"
                                >
                                    Icono
                                </Text>
                                <Flex
                                    flex="1"
                                    mr="-20px"
                                >
                                    <Image
                                        m="auto"
                                        boxSize="90px"
                                        rounded="10px"
                                        src={curso?.icono?.url}
                                    />
                                </Flex>

                                <Flex
                                    flex="1"
                                    mr="30px"
                                >
                                    <InformationFilepond
                                        noHead={true}
                                        name="icono"
                                        label="Icono"
                                        putEP={`${client}/cursos/${curso?.id}`}
                                        isDisabled={!curso?.id || gestion}
                                        setRefresh={setRefresh}
                                        labelIdle="Arrastra o selecciona el logotipo"
                                    />
                                </Flex>
                            </Flex>

                            <Flex
                                pr="25px"
                                display={curso?.tutorizado ? "flex" : "none"}
                            >
                                {curso?.tutores?.length === 0 &&
                                    <Box
                                        onClick={handleTutorClick}
                                        w="400px"
                                        h="40px"
                                        position="absolute"
                                        bg="transparent"
                                        right="51.5%"
                                        top="66.6%"
                                        zIndex="1000"
                                    />
                                }

                                <InformationAsyncCard
                                    data={curso?.tutores}
                                    label="Tutores"
                                    placeholder="Añadir tutor"
                                    isRequired={true}
                                    loadOptions={loadTutores}
                                    name="tutores"
                                    removeItem={removeTutor}
                                    updateValue={updateValue}
                                    type="tutor"
                                    showMatriculas={false}
                                    isDisabled={gestion}
                                />
                            </Flex>
                        </Box>
                    </InformationBox>

                    {isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) &&
                        <InformationBox
                            title="Portada del curso"
                        >
                            <Flex
                                w="full"
                                pr="20px"
                                align="center"
                            >
                                <Flex
                                    overflow="hidden"
                                    p="20px"
                                    w="310px"
                                    h="180px"
                                >
                                    <Image
                                        objectFit="cover"
                                        rounded="10px"
                                        src={curso?.imagen?.url}
                                    />
                                </Flex>


                                <InformationFilepond
                                    noHead={true}
                                    name="imagen"
                                    label="Portada"
                                    putEP={`${client}/cursos/${curso?.id}`}
                                    isDisabled={!curso?.id || gestion}
                                    setRefresh={setRefresh}
                                />

                            </Flex>

                        </InformationBox>
                    }
                </Flex>

                <Flex
                    direction="column"
                    flex="1"
                >
                    <InformationBox title="Descripción del curso">
                        <Box>
                            <InformationTextEditor
                                onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                name="descripcion"
                                placeholder="Introduce el texto"
                                defaultValue={curso?.descripcion}
                                updateValue={updateValue}
                                ui={{
                                    containerStyle: {
                                        padding: "10px",
                                    },
                                    editorStyle: {
                                        border: "none",
                                        borderRadius: "0 0 10px 10px"
                                    }
                                }}
                                isDisabled={gestion}
                            />
                        </Box>
                    </InformationBox>
                    
                    {estado && (
                        <InformationBox title="Examen del curso">
                        <Box>
                            <Flex
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                gap="10px"
                            >
                                
                                    <Flex
                                        onMouseEnter={() => setEditExamen(true)}
                                        onMouseLeave={() => setEditExamen(false)}
                                        w="full"
                                    >
                                        <InformationAsyncSelect
                                            name="examenId"
                                            label="Examen"
                                            defaultOptions
                                            loadOptions={loadExamenes}
                                            updateValue={updateValue}
                                            defaultValue={curso?.examen ? {
                                                value: curso.examen.id,
                                                label: curso.examen.nombre
                                            } : undefined}
                                            ui={{
                                                informationSelectStyles: informationSelectStyles,
                                                styleLabel: {
                                                    fontSize: "12px",
                                                    fontWeight: "600",
                                                    color: "font",
                                                    textTransform: "uppercase",
                                                }
                                            }}
                                        />
                                    </Flex>

                                <Flex boxSize="20px">
                                    {editExamen &&
                                        <Icon
                                            as={BiPencil}
                                            boxSize="20px"
                                        />
                                    }
                                    {!editExamen &&
                                        <Icon
                                            as={BiTrashAlt}
                                            boxSize="20px"
                                            cursor="pointer"
                                            onClick={() => removeExamen(curso?.examen?.id)}
                                        />
                                    }
                                </Flex>

                            </Flex>
                        </Box>
                    </InformationBox>
                    )}

                    <InformationBox title="Diploma customizado del curso">
                        <Box>
                            <Flex
                                p="10px 20px"
                                alignItems="center"
                                justifyContent="space-between"
                                onMouseEnter={() => setEditPlantilla(true)}
                                onMouseLeave={() => setEditPlantilla(false)}
                                gap="10px"
                            >
                                {hasDiplomaPlantillas ? (
                                    <InformationAsyncSelect
                                        name="plantillaDiplomaId"
                                        label="Plantilla diploma"
                                        defaultOptions
                                        loadOptions={loadPlantillas}
                                        updateValue={updateValue}
                                        defaultValue={curso?.plantillaDiploma ? {
                                            value: curso.plantillaDiploma.id,
                                            label: curso.plantillaDiploma.nombre
                                        } : undefined}
                                        ui={{
                                            informationSelectStyles: informationSelectStyles,
                                            styleLabel: {
                                                fontSize: "12px",
                                                fontWeight: "600",
                                                color: "font",
                                                textTransform: "uppercase",
                                            }
                                        }}
                                    />
                                ) : (
                                    <Flex
                                        alignItems="center"
                                        justifyContent="space-between"
                                        w="100%"
                                    >
                                        <Text
                                            fontSize="12px"
                                            fontWeight="600"
                                            color="font"
                                            textTransform="uppercase"
                                        >
                                            Plantilla diploma
                                        </Text>

                                        <Text color="fail">
                                            No hay plantillas de diploma disponibles
                                        </Text>
                                    </Flex>
                                )}

                                <Flex boxSize="20px">
                                    {editPlantilla && <Icon as={BiPencil} boxSize="20px" />}
                                </Flex>
                            </Flex>
                        </Box>
                    </InformationBox>

                    {moduloFUNDAE && isRoleAllowed([UserRolEnum.ADMIN], user?.role?.nombre) &&
                        <InformationBox
                            title="Datos para cuando gestionamos"
                            component={
                                <Button
                                    onClick={() => handleNewInspector()}
                                    bg="secondary"
                                    color="#FFF"
                                    p="8px 15px"
                                    borderRadius="5px"
                                    fontSize="14px"
                                    fontWeight="500"
                                    leftIcon={<Icon as={BiPlusCircle} boxSize="20px" />}
                                    isLoading={loadingInspector}
                                    _hover={{ color: "purewhite", backgroundColor: "variant" }}
                                >
                                    Nuevo inspector
                                </Button>
                            }
                        >
                            <Box>
                                <Flex
                                    borderBottom="1px solid"
                                    borderColor="light_grey"
                                    p="10px 20px"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    onMouseEnter={() => setEditAccion(true)}
                                    onMouseLeave={() => setEditAccion(false)}
                                    gap="10px"
                                >
                                    <InformationInput
                                        onSuccessCopy={() => toastNotify(toast, StatusEnumTypes.INFO, "Copiado al portapapeles")}
                                        name="accionFormativa"
                                        label="Acción formativa"
                                        defaultValue={curso?.accionFormativa || ""}
                                        updateValue={updateValue}
                                        isRequired={true}
                                    />


                                    <Flex boxSize="20px">
                                        {editAccion && <Icon as={BiPencil} boxSize="20px" />}
                                    </Flex>
                                </Flex>

                                <Flex
                                    p="10px 20px"
                                    alignItems="center"
                                    justifyContent="space-between"
                                    gap="10px"
                                >
                                    <Flex alignItems="flex-start" w="100%">
                                        <Text
                                            w="48%"
                                            mt="10px"
                                            gap="3px"
                                            color="font"
                                            fontSize="12px"
                                            fontWeight="600"
                                            textTransform="uppercase"
                                        >
                                            Inspector Asignado
                                        </Text>

                                        {(curso?.inspectores && curso?.inspectores?.length !== 0) ?
                                            <Flex
                                                direction="column"
                                                gap="10px"
                                                w="52%"
                                            >
                                                {(curso?.inspectores?.map((inspector: any, index: number) => (
                                                    <Flex
                                                        key={index}
                                                        border="1px solid"
                                                        borderColor="light_grey"
                                                        borderRadius="10px"
                                                        p="10px 15px"
                                                        mr="30px"
                                                        bg="purewhite"
                                                        gap="10px"
                                                        alignItems="center"
                                                        flex="1"
                                                    >
                                                        <CustomAvatar
                                                            name={inspector?.username + " fundae" || ""}
                                                            size="60px"
                                                            src={inspector?.avatar?.url}
                                                        />

                                                        <Flex direction="column">
                                                            <Flex gap="10px">
                                                                <Text fontWeight="600">User:</Text>
                                                                <CopyTooltip
                                                                    idName={`userInspectorSepe-${index}`}
                                                                >
                                                                    <Text
                                                                        id={`userInspectorSepe-${index}`}
                                                                        cursor="copy"
                                                                    >
                                                                        {inspector?.username}
                                                                    </Text>
                                                                </CopyTooltip>

                                                            </Flex>

                                                            <Flex gap="10px">
                                                                <Text fontWeight="600">Clave:</Text>
                                                                <CopyTooltip
                                                                    idName={`claveInspectorSepe-${index}`}
                                                                >
                                                                    <Text
                                                                        id={`claveInspectorSepe-${index}`}
                                                                        cursor="copy"
                                                                    >
                                                                        {inspector?.claveInspectorSepe}
                                                                    </Text>
                                                                </CopyTooltip>

                                                            </Flex>

                                                            <Flex gap="10px">
                                                                <Text fontWeight="600">Empresa Organizadora:</Text>
                                                                <Text>{inspector?.meta?.empresa?.nombre}</Text>
                                                            </Flex>
                                                        </Flex>
                                                    </Flex>
                                                )))}
                                            </Flex>
                                            :
                                            <Flex flex="1">
                                                <Text
                                                    p="10px 15px"
                                                    color="fail"
                                                >
                                                    No hay inspector asignado
                                                </Text>
                                            </Flex>
                                        }
                                    </Flex>
                                </Flex>
                            </Box>
                        </InformationBox>
                    }
                </Flex>

                <EmpresaInspectorModal
                    isOpen={isOpenInspector}
                    onClose={onCloseInspector}
                    cursoId={curso?.id}
                    inspectores={curso?.inspectores}
                    Refresh={setRefresh}
                />

                <NewTutorModal
                    isOpen={isOpenTutor}
                    onClose={onCloseTutor}
                    setRefreshTable={setRefresh}
                    updateGrupo={updateValue}
                />

                <NewExamenModal
                    isOpen={isOpenExamen}
                    onClose={handleExamenModalClose}
                    Refresh={setRefresh}
                />
            </Flex >
    );
}