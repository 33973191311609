import { AllCommunityModule, ModuleRegistry, provideGlobalGridOptions } from 'ag-grid-community';
import { AgGridReact } from 'ag-grid-react';
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-quartz.css";
import { useState, useMemo, useEffect } from 'react';
import { ColDef } from 'ag-grid-community';
import { themeQuartz } from 'ag-grid-community';
import { colorSchemeDarkBlue } from 'ag-grid-community';
import { Button, useColorMode, useToast, useDisclosure } from '@chakra-ui/react';
import { GridReadyEvent } from 'ag-grid-community';
import { NivelEstudiosOptions } from '../../../../../shared/utils/Types/TipoNivelEstudios';
import { CategoriaProfesionalOptions } from '../../../../../shared/utils/Types/TipoCategoriaProfesional';
import { GruposInt } from '../../../../../interfaces/GruposInt';
import { useClient } from '../../../../../shared/context/client.context';
import { uploadMatriculasImport } from '../../../../../shared/middlewares/adjuntos.middleware';
import ModalTutores from '../../../components/Modals/ModalTutores';

const myTheme = themeQuartz.withPart(colorSchemeDarkBlue);

ModuleRegistry.registerModules([AllCommunityModule]);

provideGlobalGridOptions({ theme: "legacy" });

const darkThemeOverrides = {
    baseTheme: 'ag-theme-quartz-dark',
    defaults: {
        headerBackground: '#1a1a1a',
        backgroundColor: '#242424',
        cellBorderColor: '#303030',
    },
    gridStyle: {
        '--ag-background-color': '#242424',
        '--ag-header-background-color': '#1a1a1a',
        '--ag-odd-row-background-color': '#2d2d2d',
        '--ag-header-foreground-color': '#fff',
        '--ag-foreground-color': '#fff',
        '--ag-border-color': '#303030',
        '--ag-row-hover-color': '#3d3d3d',
        '--ag-selected-row-background-color': '#4a4a4a',
        '--ag-input-focus-border-color': '#4f7fff',
        '--ag-range-selection-border-color': '#4f7fff',
        '--ag-input-border-color': '#303030',
    }
};

interface Participante {
    nombre: string;
    apellidos: string;
    email: string;
    telefono: string;
    dni: string;
    curso?: string;
    nivelEstudios?: string;
    categoriaProfesional?: string;
    costeHora?: number;
    fijoDiscontinuo?: boolean;
    fechaNacimiento?: string;
    discapacidad?: string;
    excedencia?: string;
    grupoCotizacion?: number;
    empresa?: string;
    tutorId?: string | null;
}

interface Props {
    rows: number;
    onMatriculasEnviadas: () => void;
    grupo: GruposInt;
    refreshGrupo: () => void;
}

export default function PrematriculasTable({ grupo, rows, onMatriculasEnviadas, refreshGrupo }: Props) {
    const { colorMode } = useColorMode();
    const toast = useToast();
    const client = useClient();
    const { isOpen, onOpen, onClose } = useDisclosure();
    const [tutorId, setTutorId] = useState<string | null>(null);
    const filaVacia: Participante = {
        nombre: '',
        apellidos: '',
        email: '',
        telefono: '',
        dni: '',
        nivelEstudios: '',
        categoriaProfesional: '',
        costeHora: 0,
        fijoDiscontinuo: false,
        empresa: grupo?.empresas?.length === 1 ? grupo.empresas[0].id : '',
        tutorId: grupo?.tutores?.length === 1 ? grupo.tutores[0].id : null
    };

    const [rowData, setRowData] = useState<Participante[]>(
        Array(rows).fill(null).map(() => ({ ...filaVacia }))
    );

    useEffect(() => {
        if (grupo?.prematriculas?.prematriculas) {
            const prematriculasArray = Array.isArray(grupo.prematriculas.prematriculas)
                ? grupo.prematriculas.prematriculas
                : [grupo.prematriculas.prematriculas];

            if (prematriculasArray.length > 0) {
                const mappedData = prematriculasArray.map(prematricula => ({
                    nombre: prematricula.nombre || '',
                    apellidos: prematricula.apellidos || '',
                    email: prematricula.email || '',
                    telefono: prematricula.telefono || '',
                    dni: prematricula.dni || '',
                    nivelEstudios: prematricula.nivelEstudios || '',
                    categoriaProfesional: prematricula.categoriaProfesional || '',
                    costeHora: prematricula.costeHora || 0,
                    fijoDiscontinuo: prematricula.fijoDiscontinuo || false,
                    empresa: prematricula.empresaId || '',
                    tutorId: prematricula.tutorId || (grupo?.tutores?.length === 1 ? grupo.tutores[0].id : null)
                }));

                setRowData(mappedData);
            }
        }
    }, [grupo?.prematriculas, grupo?.empresas, grupo?.tutores]);

    const colDefs = useMemo<ColDef[]>(() =>
        grupo?.fundae ? [
            {
                field: "dni",
                headerName: "NIF o NIE",
                editable: true,
                minWidth: 120,
                enableCellChangeFlash: true,
                onCellValueChanged: (params) => {
                    if (params.node) {
                        params.api.refreshCells({
                            force: true,
                            columns: [params.column.getId()],
                            rowNodes: [params.node]
                        });
                    }
                },
                cellStyle: (params) => {
                    if (!params.value) return null;
                    const dniRegex = /^[0-9]{8}[A-Z]$/;
                    const nieRegex = /^[XYZ][0-9]{7}[A-Z]$/;
                    return (dniRegex.test(params.value) || nieRegex.test(params.value)) ? { backgroundColor: 'transparent' } : { backgroundColor: '#ff000030' };
                }
            },
            {
                field: "nombre",
                headerName: "Nombre",
                editable: true,
                minWidth: 120,
            },
            {
                field: "apellidos",
                headerName: "Apellidos",
                editable: true,
                minWidth: 150,
            },
            {
                field: "email",
                headerName: "Email personal",
                editable: true,
                minWidth: 200,
                cellStyle: (params) => {
                    const value = params.value?.toString().trim();
                    if (!value) return null;
                    const tieneArroba = value.includes('@');
                    const tienePunto = value.split('@')[1]?.includes('.');
                    return (tieneArroba && tienePunto) ? { backgroundColor: 'transparent' } : { backgroundColor: '#ff000030' };
                }
            },
            {
                field: "telefono",
                headerName: "Teléfono personal",
                editable: true,
                minWidth: 140,
                cellStyle: (params) => {
                    if (!params.value) return null;
                    const phoneRegex = /^\d{9}$/;
                    return phoneRegex.test(params.value) ? { backgroundColor: 'transparent' } : { backgroundColor: '#ff000030' };
                }
            },
            {
                field: "nivelEstudios",
                headerName: "Nivel de estudios",
                editable: true,
                minWidth: 150,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: NivelEstudiosOptions.map(option => option.value)
                },
                valueFormatter: (params) => {
                    const option = NivelEstudiosOptions.find(opt => opt.value === params.value);
                    return option ? option.label : params.value;
                }
            },
            {
                field: "categoriaProfesional",
                headerName: "Cat. profesional",
                editable: true,
                minWidth: 150,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: CategoriaProfesionalOptions.map(option => option.value)
                },
                valueFormatter: (params) => {
                    const option = CategoriaProfesionalOptions.find(opt => opt.value === params.value);
                    return option ? option.label : params.value;
                }
            },
            {
                field: "costeHora",
                headerName: "Coste salarial/hora",
                type: 'numericColumn',
                editable: true,
                minWidth: 150,
                valueFormatter: (params) => {
                    if (!params.value) return '';
                    return `${params.value.toFixed(2)} €`;
                }
            },
            {
                field: "fijoDiscontinuo",
                headerName: "Fijo discontinuo",
                editable: true,
                minWidth: 130,
                cellEditor: 'agCheckboxCellEditor',
                cellRenderer: 'agCheckboxCellRenderer',
                cellStyle: { textAlign: 'center' }
            },
            ...(grupo?.empresas && grupo.empresas.length > 1 ? [{
                field: "empresa",
                headerName: "Empresa",
                editable: true,
                minWidth: 150,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: grupo?.empresas?.map(empresa => empresa.id) || []
                },
                valueFormatter: (params: { value: string }) => {
                    const empresa = grupo?.empresas?.find(emp => emp.id === params.value);
                    return empresa ? empresa.nombre : params.value;
                }
            }] : []),
            ...(grupo?.tutores && grupo.tutores.length > 1 ? [{
                field: "tutorId",
                headerName: "Tutor",
                editable: true,
                minWidth: 150,
                cellEditor: 'agSelectCellEditor',
                cellEditorParams: {
                    values: grupo?.tutores?.map(tutor => tutor.id) || []
                },
                valueFormatter: (params: { value: string }) => {
                    const tutor = grupo?.tutores?.find(t => t.id === params.value);
                    return tutor ? `${tutor.nombre} ${tutor.apellidos}` : params.value;
                }
            }] : [])
        ] : [
            {
                field: "dni",
                headerName: "Nif o Nie",
                editable: true,
                minWidth: 120,
                cellStyle: (params) => {
                    if (params.value) {
                        const dniRegex = /^[0-9]{8}[A-Z]$/;
                        const nieRegex = /^[XYZ][0-9]{7}[A-Z]$/;
                        return (dniRegex.test(params.value) || nieRegex.test(params.value)) ? { backgroundColor: 'transparent' } : { backgroundColor: '#ff000030' };
                    }
                    return null;
                }
            },
            {
                field: "nombre",
                headerName: "Nombre",
                editable: true,
                minWidth: 120,
                valueFormatter: (params) => params.value?.toLowerCase()
            },
            {
                field: "apellidos",
                headerName: "Apellidos",
                editable: true,
                minWidth: 150,
                valueFormatter: (params) => params.value?.toLowerCase()
            },
            {
                field: "email",
                headerName: "Email",
                editable: true,
                minWidth: 200,
                cellStyle: (params) => {
                    const value = params.value?.toString().trim();
                    if (!value) return null;

                    const tieneArroba = value.includes('@');
                    const tienePunto = value.split('@')[1]?.includes('.');

                    return (tieneArroba && tienePunto) ? { backgroundColor: 'transparent' } : { backgroundColor: '#ff000030' };
                }
            },
            {
                field: "telefono",
                headerName: "Teléfono",
                editable: true,
                minWidth: 120,
                cellStyle: (params) => {
                    if (!params.value) return null;
                    const phoneRegex = /^\d{9}$/;
                    return phoneRegex.test(params.value) ? { backgroundColor: 'transparent' } : { backgroundColor: '#ff000030' };
                }
            }
        ]
        , [grupo?.fundae, grupo?.empresas, grupo?.tutores]);

    const getTableHeight = (rowCount: number) => {
        const rowHeight = 48;
        const headerHeight = 50;
        const minHeight = 400;
        const maxHeight = 800;

        const calculatedHeight = (rowCount * rowHeight) + headerHeight;
        return Math.min(Math.max(calculatedHeight, minHeight), maxHeight);
    };

    const handleSubmit = async (tutorIdFromModal?: string) => {
        if ((grupo?.tutores ?? []).length > 1 && !tutorIdFromModal) {
            onOpen();
            return;
        }

        setTutorId(tutorIdFromModal || grupo?.tutores?.[0]?.id || null);

        const alumnosValidos = rowData.every(alumno => {
            const camposBasicos =
                alumno.nombre &&
                alumno.apellidos &&
                alumno.email &&
                alumno.telefono &&
                alumno.dni;

            const camposFundae =
                alumno.nivelEstudios &&
                alumno.categoriaProfesional &&
                alumno.costeHora;

            if (!grupo?.fundae) return camposBasicos;
            else return camposBasicos && camposFundae;
        });

        if (!alumnosValidos) {
            toast({
                title: "Error",
                description: "Por favor, complete todos los campos requeridos para cada alumno",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
            return;
        }

        try {
            const matriculas = {
                users: rowData.map(alumno => ({
                    nombre: alumno.nombre || null,
                    apellidos: alumno.apellidos || null,
                    email: alumno.email || null,
                    telefono: alumno.telefono || null,
                    dni: alumno.dni || null,
                    curso: alumno.curso || null,
                    grupoId: grupo?.id,
                    empresaId: alumno.empresa || (grupo?.empresas?.length === 1 ? grupo.empresas[0].id : null),
                    extraFundae: {
                        nivelEstudios: alumno.nivelEstudios || null,
                        categoriaProfesional: alumno.categoriaProfesional || null,
                        costeHora: alumno.costeHora || null,
                        fijoDiscontinuo: alumno.fijoDiscontinuo ?? false,
                    },
                    tutorId: alumno.tutorId || (grupo?.tutores?.length === 1 ? grupo.tutores[0].id : null)
                }))
            };

            await uploadMatriculasImport({
                data: matriculas?.users || [],
                client: client,
            });

            onMatriculasEnviadas();
            refreshGrupo();

            toast({
                title: "Éxito",
                description: "Los alumnos han sido matriculados correctamente",
                status: "success",
                duration: 5000,
                isClosable: true,
            });

        } catch (error) {
            toast({
                title: "Error",
                description: "Ha ocurrido un error al enviar los alumnos",
                status: "error",
                duration: 5000,
                isClosable: true,
            });
        }
    };

    const onGridReady = (params: GridReadyEvent) => {
        setTimeout(() => {
            const firstCell = params.api.getDisplayedRowAtIndex(0);
            if (firstCell) {
                params.api.setFocusedCell(0, 'dni');
                params.api.startEditingCell({
                    rowIndex: 0,
                    colKey: 'dni'
                });
            }
        }, 100);
    };

    return (
        <div style={{
            display: 'flex',
            flexDirection: 'column',
            gap: '1rem',
            width: '100%',
            height: '100%'
        }}>
            <div
                className={colorMode === 'dark' ? 'ag-theme-quartz-dark' : 'ag-theme-quartz'}
                style={{
                    width: '100%',
                    height: getTableHeight(rowData.length),
                    ...(colorMode === 'dark' ? darkThemeOverrides.gridStyle : {})
                }}
            >
                <AgGridReact
                    rowData={rowData}
                    columnDefs={colDefs}
                    defaultColDef={{
                        sortable: false,
                        filter: false,
                        resizable: true,
                        flex: 1,
                        suppressKeyboardEvent: (params) => {
                            const { event } = params;
                            return event.key === 'Enter' && !event.shiftKey;
                        }
                    }}
                    onGridReady={onGridReady}
                />
            </div>

            <Button
                px="40px"
                bg="main"
                color="white"
                onClick={() => handleSubmit()}
                isDisabled={rowData.length === 0}
                mt="20px"
                w="fit-content"
                mx="auto"
            >
                Matricular
            </Button>

            <ModalTutores
                isOpen={isOpen}
                onClose={onClose}
                onSubmit={handleSubmit}
                tutores={grupo?.tutores}
            />
        </div>
    )
}
