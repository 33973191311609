import React from 'react'
import { Modal, ModalOverlay, ModalContent, ModalHeader, ModalBody, ModalFooter, Button, FormControl, FormLabel, Select } from '@chakra-ui/react'

interface Props {
    isOpen: boolean;
    onClose: () => void;
    onSubmit: (tutorId: string) => void;
    tutores: any;
}

export default function ModalTutores({ isOpen, onClose, onSubmit, tutores }: Props) {
    const [selectedTutor, setSelectedTutor] = React.useState<string>("");

    return (
        <Modal
            isOpen={isOpen}
            onClose={onClose}
            size="xl"
            isCentered
        >
            <ModalOverlay />
            <ModalContent>
                <ModalHeader>
                    Asociar Tutor
                </ModalHeader>

                <ModalBody gap="10px">
                    <FormControl>
                        <FormLabel>Selecciona un tutor</FormLabel>
                        <Select
                            placeholder="Seleccione un tutor"
                            value={selectedTutor}
                            onChange={(e) => setSelectedTutor(e.target.value)}
                        >
                            {tutores?.map((tutor: any) => (
                                <option key={tutor.id} value={tutor.id}>
                                    {tutor.nombreCompleto}
                                </option>
                            ))}
                        </Select>
                    </FormControl>
                </ModalBody>

                <ModalFooter>
                    <Button
                        bg="transparent"
                        color="main"
                        border="2px solid"
                        borderColor="main"
                        mr={3}
                        onClick={onClose}
                    >
                        Cancelar
                    </Button>
                    <Button
                        bg="main"
                        color="white"
                        onClick={() => {
                            onSubmit(selectedTutor);
                            onClose();
                        }}
                        isDisabled={!selectedTutor}
                    >
                        Aceptar
                    </Button>
                </ModalFooter>
            </ModalContent>
        </Modal>
    )
}
