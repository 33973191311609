import { debounce, truncate } from 'lodash';
import { useCallback, useState } from 'react';
import { Box, Flex, Icon, Input, InputGroup, InputLeftElement, InputRightElement, Spinner, Text } from '@chakra-ui/react';
import { BiChevronLeft, BiSearch } from 'react-icons/bi';
import { GroupTypes } from '../Topbar/Topbar';

interface Props {
    loadOptions: any;
    onClick: (e: any, group: string) => void;
    placeholder?: string;
}

export const CustomGroupAsyncSelect = ({
    onClick,
    loadOptions,
    placeholder = 'Escribe para buscar',
}: Props) => {
    const [inputValue, setInputValue] = useState('');
    const [menu, setMenu] = useState<boolean>(false);
    const [searchOptions, setSearchOptions] = useState<any>(null);
    const [isLoading, setIsLoading] = useState<boolean>(false)

    const debouncedLoadOptions = useCallback(
        debounce(async (inputValue: string) => {
            if (inputValue && inputValue !== '') {
                setIsLoading(true);
                const options = await loadOptions(inputValue);

                setSearchOptions(options);
                setIsLoading(false);
            }
            else
                setSearchOptions(null);
        }, 300),
        [loadOptions]
    );

    const handleInputChange = (e: React.ChangeEvent<HTMLInputElement>) => {
        const value = e.target.value;

        setInputValue(value);
        debouncedLoadOptions(value);
    };

    const handleClick = (option: any, group: string) => {
        debouncedLoadOptions('')
        setInputValue('')
        setMenu(false)

        onClick(option, group)
    }

    return (
        <Box
            position="relative"
            minW="700px"
        >
            <InputGroup>
                <Input
                    color='font'
                    fontSize="16px"
                    fontWeight="400"
                    value={inputValue}
                    onChange={handleInputChange}
                    placeholder={placeholder}
                    onFocus={() => setMenu(true)}
                    onBlur={() =>
                        setTimeout(() => {
                            setMenu(false)
                        }, 200)
                    }
                />

                <InputLeftElement>
                    <Icon
                        as={BiSearch}
                        boxSize="20px"
                    />
                </InputLeftElement>

                <InputRightElement>
                    <Icon
                        as={BiChevronLeft}
                        boxSize="24px"
                        transition={'transform 0.3s ease'}
                        transform={menu ? 'rotate(-90deg)' : 'rotate(0deg)'}
                    />
                </InputRightElement>
            </InputGroup>

            <Box
                position="absolute"
                zIndex={999}
                maxH={300}
                overflowY="scroll"
                css={{
                    '&::-webkit-scrollbar': {
                        width: '5px',
                    },
                    '&::-webkit-scrollbar-thumb': {
                        background: "#E2E8F0",
                        borderRadius: '20px',
                    },
                }}
                top="115%"
                left="0"
                w="100%"
                bg="purewhite"
                border="1px solid #E2E8F0"
                boxShadow="0px 4px 4px rgba(0, 0, 0, 0.05)"
                rounded="8px"
                display={menu ? 'block' : 'none'}
                padding="12px 8px"
            >
                {searchOptions ?
                    <Flex
                        direction="column"
                        gap="10px"
                    >
                        {Object.keys(searchOptions || {}).map((group: string, index: number) => (
                            searchOptions[group]?.options?.length > 0 &&
                            <Box
                                key={index}
                            >
                                <Text
                                    fontSize="14px"
                                    fontWeight="600"
                                    color="font"
                                    textTransform="uppercase"
                                    mb="4px"
                                >
                                    {searchOptions[group].group}
                                </Text>

                                {searchOptions[group]?.options?.length > 0 ?
                                    searchOptions[group]?.options?.map((option: any, i: number) => (
                                        <Flex
                                            key={i}
                                            alignItems="center"
                                            gap="10px"
                                            cursor="pointer"
                                            _hover={{ bg: "light_grey" }}
                                            onClick={() => handleClick(option, searchOptions[group].group)}
                                            p="8px"
                                        >
                                            <Text
                                                fontSize="14px"
                                                fontWeight="400"
                                                color="font"
                                            >
                                                {searchOptions[group].group === GroupTypes.GRUPOS
                                                    ? <Flex
                                                        gap="4px"
                                                    >
                                                        <Text as={"strong"}>{option?.data?.nombre}</Text>
                                                        <Text>- {option?.data?.fechaInicio ? new Date(option?.data?.fechaInicio).toLocaleDateString('es-ES', { month: 'short', year: '2-digit' }).replace('.', '').split(' ').map(word => word.charAt(0).toUpperCase() + word.slice(1)).join(' ') : ''}</Text>
                                                        <Text>- {option?.data?.curso?.nombre}</Text>
                                                        {option?.data?.empresas && option?.data?.empresas.length > 0 &&
                                                            <Text>- {truncate(option?.data?.empresas[0]?.nombre, { length: 35 })}</Text>
                                                        }
                                                    </Flex>
                                                    : option.label
                                                }
                                            </Text>
                                        </Flex>
                                    ))
                                    :
                                    <Text
                                        fontSize="14px"
                                        fontWeight="400"
                                        color="secondary_font"
                                    >
                                        No hay resultados
                                    </Text>
                                }
                            </Box>
                        ))}
                    </Flex>
                    :
                    !isLoading ?
                        <Text
                            textAlign="center"
                        >
                            Escribe para mostrar opciones...
                        </Text>
                        :
                        <Flex
                            justifyContent="center"
                            alignItems="center"
                            w="100%"
                        >
                            <Spinner
                                size="sm"
                                color="secondary"
                                thickness="2px"
                                speed="0.65s"
                                emptyColor="light_grey"
                            />
                        </Flex>
                }
            </Box>
        </Box>

    );
};